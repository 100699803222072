import { NextPage } from 'next';

import { LandingPage } from '../components';

const LandingNextPage: NextPage = () => {
  return <LandingPage />;
};

// eslint-disable-next-line import/no-default-export
export default LandingNextPage;
